import React, { useState, useEffect } from "react";

function Hero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const heroContainerStyle = {
    position: "relative",
    width: "100%",
    height: isMobile ? "calc(90vh - 50px)" : "calc(100vh - 40px)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const videoStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1, // Send video to background
  };

  const textContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 24px",
    fontSize: isMobile ? "1.2rem" : "1.5rem",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark translucent background
    backdropFilter: "blur(10px)", // Smooth glass effect
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s",
  };

  const arrowButtonStyle = {
    position: "absolute",
    bottom: "20px", // Adjusts button position at the bottom
    width: "50px",
    height: "50px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",// Light transparent
    backdropFilter: "blur(10px)", // Glass effect
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "#fff",
    cursor: "pointer",
    transition: "0.3s",
    animation: "bounce 1.5s infinite", // Bouncing effect
  };

  return (
    <div style={heroContainerStyle}>
      <video
        src="https://ita-architecture-images.s3.eu-west-2.amazonaws.com/ani-velika/Ani_Velika.mp4"
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        controlsList="nofullscreen"
        style={videoStyle}
      />
      <div style={textContainerStyle}>ITArchitecture</div>
      <div style={arrowButtonStyle} onClick={() => window.scrollBy({ top: window.innerHeight, behavior: "smooth" })}>
        ↓
      </div>

      {/* Keyframes for animation */}
      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(10px);
            }
          }
        `}
      </style>
    </div>
  );
}

export default Hero;
